import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '@/components/common/Loader';
import { GoBackButtons } from '@/components/common/buttons/GoBackButtons';
import { TrendingGridComponent } from '@/components/common/recipe/masonry-recipe/TrendingGridComponent';
import { useAuth } from '@/context/useAuth';
import { useGetAllRandomRecipes } from '@/hooks/recipes/useGetAllRandomRecipes';
import { IRecipe } from '@/interface/recipe.interfaces';

export const ExploreRecipes = () => {
	const [recipes, setRecipes] = useState<IRecipe[]>([]);
	const [skip, setSkip] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const take = 18;
	const { userHasSubscription } = useAuth();

	const {
		allRandomRecipes,
		isLoadingAllRandomRecipes,
		isErrorAllRandomRecipes,
		errorAllRandomRecipes,
	} = useGetAllRandomRecipes(take, skip);

	useEffect(() => {
		if (allRandomRecipes) {
			setRecipes((prevRecipes) => [...prevRecipes, ...allRandomRecipes.data]);
			setHasMore(allRandomRecipes.data.length > 0);
		}
	}, [allRandomRecipes]);

	const fetchMoreData = () => {
		if (!isLoadingAllRandomRecipes && hasMore) {
			setSkip((prevSkip) => prevSkip + take);
		}
	};

	if (isLoadingAllRandomRecipes && recipes.length === 0) {
		return (
			<div className="flex justify-center items-center">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorAllRandomRecipes) {
		return (
			<div
				className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
				data-cy="explore-recipes-error"
			>
				<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
					{errorAllRandomRecipes?.message}
				</p>
			</div>
		);
	}

	if (recipes.length === 0) {
		return (
			<div className="flex justify-center text-3xl/[34px] items-center pt-10">
				<p>No recipes yet</p>
			</div>
		);
	}

	const renderRecipeGrid = () => {
		const grids = [];
		for (let i = 0; i < recipes.length; i += 18) {
			grids.push(
				<TrendingGridComponent
					key={i}
					recipes={recipes.slice(i, i + 18)}
					itemsCenter={i > 0}
					columnReverse={i % 2 === 0}
					isExploreRecipesPage
					userHasSubscription={userHasSubscription}
				/>,
			);
		}
		return grids;
	};

	return (
		<div data-cy="infinite-scroll-container">
			<div className="flex flex-col justify-start tracking-tighter px-[18px] sm:px-6 mt-6 gap-2">
				<div className="self-start">
					<GoBackButtons />
				</div>
				<h2
					data-cy="explore-recipes-title"
					className=" font-semibold text-2xl sm:translate-y-[-2.5px] sm:text-3xl md:text-4xl 2xl:text-5xl text-center sm:text-left "
				>
					Explore <span className="text-brandRed">Recipes</span>
				</h2>
			</div>

			<InfiniteScroll
				dataLength={recipes.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
						Loading more recipes...
					</p>
				}
				endMessage={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl font-helveticaNeue">
						No more recipes
					</p>
				}
			>
				<section className="flex flex-wrap justify-center mt-10 mb-10  ">
					<div
						data-cy="explore-recipes-cards"
						className="w-full flex flex-wrap justify-center"
					>
						{renderRecipeGrid()}
					</div>
				</section>
			</InfiniteScroll>
		</div>
	);
};
