import { useState } from 'react';
import { Link } from 'react-router-dom';

import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import useWindowWidth from '@/hooks/useWindowWidth';

import kitchedRedLogo from '@assets/image/kitched-red-logo.png';
import burgerMenuWhite from '@assets/image/white-burger-menu-icon.png';
import burgerMenuYellow from '@assets/image/yellow-burger-menu-icon.png';

interface INavbarTabsProps {
	state: string;
	handleClick: (tab: string) => void;
	text: string;
}

const NavbarTab = ({ state, handleClick, text }: INavbarTabsProps) => {
	return (
		<a
			className={`font-verdana font-normal text-[20px] leading-5 ${
				state === text ? 'text-white' : 'text-[#6a0136]'
			} py-[12px] px-[12px]  ${state === text && 'bg-[#6a0136] rounded-md'}`}
			href={`#${text}`}
			onClick={() => handleClick(text)}
		>
			{upperCaseFirstLetter(text)}
		</a>
	);
};

interface INavbarButtonsProps {
	closeBurgerMenu?: () => void;
	selectedTab: string;
	setSelectedTab: (tab: string) => void;
}
const NavbarButtons = ({
	closeBurgerMenu,
	selectedTab,
	setSelectedTab,
}: INavbarButtonsProps) => {
	function handleClick(tab: string) {
		setSelectedTab(tab);
		closeBurgerMenu && closeBurgerMenu();
	}

	return (
		<>
			<NavbarTab state={selectedTab} handleClick={handleClick} text="product" />
			<NavbarTab
				state={selectedTab}
				handleClick={handleClick}
				text="features"
			/>
			<NavbarTab state={selectedTab} handleClick={handleClick} text="pricing" />
			<Link
				to="/signup"
				className="bg-brandRedStrong text-white py-[7px] px-[14px] rounded-xl text-xl hover:bg-black transition duration-700 font-normal font-verdana"
				data-cy="landing-signup-redirection"
			>
				Get Kitched
			</Link>
		</>
	);
};

export const NavbarLandingPage = () => {
	const [selectedTab, setSelectedTab] = useState('product');
	const [burgerMenu, setBurgerMenu] = useState(false);
	const windowWidth = useWindowWidth();

	if (windowWidth > 992) {
		return (
			<nav
				className="fixed bg-white z-10 w-auto h-[50px] top-0 flex justify-center items-center gap-4 p-5 border-[1px] rounded-2xl border-[#ddd] mt-1"
				data-cy="landing-navbar"
			>
				<a
					className="flex justify-center items-center"
					href="#product"
					onClick={() => setSelectedTab('product')}
				>
					<img src={kitchedRedLogo} alt="" className="w-10 h-10" />
				</a>
				<NavbarButtons
					setSelectedTab={setSelectedTab}
					selectedTab={selectedTab}
				/>
			</nav>
		);
	} else {
		return (
			<div
				className="fixed top-0 w-full flex flex-col gap-4 justify-center items-center z-20"
				data-cy="landing-navbar-mobile"
			>
				<nav className="bg-white z-10 w-[202px] h-[50px]  flex justify-between items-center gap-4 px-5 border-[1px] rounded-2xl border-[#ddd] mt-1">
					<a
						href="#product"
						className="flex justify-center items-center"
						onClick={() => setSelectedTab('product')}
						data-cy="landing-logo-mobile"
					>
						<img src={kitchedRedLogo} alt="" className="w-10 h-10" />
					</a>
					<button
						onClick={() => setBurgerMenu(!burgerMenu)}
						className={`py-[11px] px-[12px] ${
							burgerMenu && 'bg-brandRedStrong  rounded-xl'
						}`}
						data-cy="landing-burger-menu"
					>
						<img
							src={burgerMenu ? burgerMenuWhite : burgerMenuYellow}
							alt=""
							className="w-6 h-6 "
						/>
					</button>
				</nav>
				{burgerMenu && (
					<div className="flex flex-col justify-center items-center gap-8 bg-white w-full py-6  opacity-0 animate-fadeInDown">
						<NavbarButtons
							closeBurgerMenu={() => setBurgerMenu(false)}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
						/>
					</div>
				)}
			</div>
		);
	}
};
