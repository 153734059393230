import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter } from 'react-router-dom';

import { ErrorBoundaryFallback } from '@/components/error-boundary/ErrorBoundaryFallback';
import { SignupLanding } from '@/pages/signup-landing/SignupLanding';

const routerLandingPage = createBrowserRouter([
	{
		path: '/',
		element: (
			<ErrorBoundary fallbackRender={() => <ErrorBoundaryFallback />}>
				<SignupLanding />
			</ErrorBoundary>
		),
	},
]);

export default routerLandingPage;
