export const LOGIN_SUCCESSFUL = 'Login successful!';
export const LOGIN_COMMON_ERROR = 'Error during login. Check your credentials.';
export const NOT_CONFIRMED_USER_COGNITO_ERROR = 'User is not confirmed.';
export const USER_NOT_AUTHORIZED_COGNITO_ERROR = 'User not authorized';
export const USER_NOT_EXISTS = 'User does not exist';
export const NOT_CONFIRMED_USER_ERROR =
	'User is not confirmed. Check your email.';
export const REGISTER_SUCCESSFUL = 'Registration successful!';
export const REGISTER_COMMON_ERROR =
	'Error during registration or authentication';
