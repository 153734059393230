import 'swiper/css/free-mode';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Loader } from '@/components/common/Loader';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { useGetMostLikedRecipes } from '@/hooks/recipes/useGetMostLikedRecipes';
import { RecipeSort } from '@/interface/filter.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

export const RecipesSliderLandingPage = () => {
	const take = 24;
	const sort = RecipeSort.LATEST;
	const mockUserSubscription = true;

	const {
		mostLikedRecipes: firstSliderRecipes,
		errorMostLikedRecipes: firstSliderError,
		isLoadingMostLikedRecipes: isLoadingFirstSlider,
	} = useGetMostLikedRecipes({ take, skip: 0, sort });

	if (isLoadingFirstSlider)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (firstSliderError) {
		return (
			<div className="flex justify-center text-2xl items-center">
				<p>{firstSliderError?.message}</p>
			</div>
		);
	}

	const renderSlider = (recipes: IRecipe[], reverseDirection: boolean) => (
		<div
			style={{ pointerEvents: 'none' }}
			className="w-full mb-8"
			data-cy="recipe-slider"
		>
			<Swiper
				slidesPerView={4.3}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
					reverseDirection: reverseDirection,
				}}
				modules={[Autoplay]}
				className="mySwiper"
				breakpoints={sliderBreakpoints}
				loop={true}
			>
				{recipes?.map((recipe: IRecipe) => (
					<SwiperSlide key={recipe.id}>
						<RecipesCard
							recipe={recipe}
							isOnCarusel={true}
							userHasSubscription={mockUserSubscription}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);

	return (
		<section
			data-cy="landing-page-recipes-section"
			className="flex flex-col justify-center items-center ml-2 md:ml-10 "
			id="features"
		>
			{!firstSliderRecipes?.data.length ? (
				<RecipeDinamicCardSkeletond
					height="normal"
					message="No recipes found"
				/>
			) : (
				<>
					{renderSlider(
						firstSliderRecipes.data.slice(0, 12) as IRecipe[],
						false,
					)}
					{renderSlider(
						firstSliderRecipes.data.slice(12, 24) as IRecipe[],
						true,
					)}
				</>
			)}
		</section>
	);
};

const sliderBreakpoints = {
	0: {
		slidesPerView: 1.1,
	},
	335: {
		slidesPerView: 1.3,
	},
	430: {
		slidesPerView: 1.7,
	},
	580: {
		slidesPerView: 2.1,
	},
	690: {
		slidesPerView: 2.5,
	},
	860: {
		slidesPerView: 3.1,
	},
	1024: {
		slidesPerView: 3.3,
	},
	1130: {
		slidesPerView: 4.3,
	},
	1355: {
		slidesPerView: 5.3,
	},
	1630: {
		slidesPerView: 6.3,
	},
};
