import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RenderVideoOrImage } from '../RenderVideoOrImage';
import './styles.css';

import lockBlackIcon from '@/assets/svg/lock-black-icon.svg';
import { PopUp } from '@/components/common/PopUp';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import { CardInfo } from '@/components/common/recipe/recipe-card/CardInfo';
import { useAddRecipeToCollection } from '@/hooks/collection/useAddRecipeToCollection';
import { useRemoveRecipeFromCollection } from '@/hooks/collection/useRemoveRecipeFromCollection';
import { useDeleteRecipe } from '@/hooks/recipes/useDeleteRecipe';
import useWindowWidth from '@/hooks/useWindowWidth';
import { ICollection } from '@/interface/collection.interfaces';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { IRecipe } from '@/interface/recipe.interfaces';
import { logVideoPlay } from '@/service/analytics/analytics';

interface RecipeCardProps {
	recipe: IRecipe;
	isChefProfile?: boolean;
	isChefPage?: boolean;
	hideIcons?: boolean;
	setSliderStyle?: (state: string) => void;
	userHasSubscription?: boolean;
	paginationOptions?: {
		take: number;
		skip: number;
	};
	isOnCarusel?: boolean;
	disableNavigation?: boolean;
	onAddRecipe?: (recipeId: number) => void;
	onRemoveRecipe?: (recipeId: number) => void;
	collection?: ICollection;
	onToggleInCollection?: (recipeId: number) => void;
}

export const RecipesCard = ({
	recipe,
	isChefProfile,
	isChefPage,
	hideIcons,
	setSliderStyle,
	userHasSubscription,
	paginationOptions,
	isOnCarusel,
	disableNavigation = false,
	collection,
	onAddRecipe,
	onRemoveRecipe,
	onToggleInCollection,
}: RecipeCardProps) => {
	const [play, setPlay] = useState(false);
	const [open, setOpen] = useState(false);
	const [isVideoFetched, setIsVideoFetched] = useState(false);
	const navigate = useNavigate();
	const { deleteRecipeMutation } = useDeleteRecipe(
		paginationOptions || defaultPortfolioPaginationOptions,
		isChefProfile,
	);
	const [recipeOverEffect, setRecipeOverEffect] = useState(false);

	const { instagramInformation, isFree } = recipe;
	const isVideo = instagramInformation?.media_type === 'VIDEO';
	const hasThumbnail = isVideo && instagramInformation?.thumbnail_url;
	const mediaUrl =
		instagramInformation?.media_url || '/images/recipe-pasta.png';
	const dontHaveAccessToRecipe =
		!userHasSubscription && !isFree && !isChefProfile;

	const hoverTimeoutRef = useRef<number | null>(null);
	const windowWidth = useWindowWidth();

	const addRecipeMutation = useAddRecipeToCollection();
	const removeRecipeMutation = useRemoveRecipeFromCollection();
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const handlePlayToggle = () => {
		setIsVideoFetched(true);
		setPlay(!play);
	};
	const handlePlayClick = () => {
		if (isVideo) {
			const chefFullName = `${recipe.chef?.user?.name || 'Unknown'} ${
				recipe.chef?.user?.last_name || 'Unknown'
			}`;
			logVideoPlay(recipe.uuid, recipe.title, chefFullName);
		}
	};

	const handleMouseEnter = () => {
		if (hoverTimeoutRef.current === null) {
			hoverTimeoutRef.current = window.setTimeout(() => {
				windowWidth > 1024 && setRecipeOverEffect(true);
				windowWidth > 1024 && setSliderStyle && setSliderStyle(recipe.uuid);
				isVideo && handlePlayToggle();
				isVideo && handlePlayClick();
			}, 1000);
		}
	};

	const handleMouseLeave = () => {
		if (hoverTimeoutRef.current !== null) {
			setRecipeOverEffect(false);
			setSliderStyle && setSliderStyle('');
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
			play && handlePlayToggle();
		}
	};

	const isRecipeInCollection = collection?.recipes?.some(
		(r) => r.id === recipe.id,
	);

	const handleToggleInCollection = () => {
		if (!collection) return;

		if (isRecipeInCollection) {
			if (onRemoveRecipe) onRemoveRecipe(recipe.id);
			removeRecipeMutation.mutate({
				collectionId: collection.id,
				recipeId: recipe.id,
			});
		} else {
			if (onAddRecipe) onAddRecipe(recipe.id);
			addRecipeMutation.mutate({
				collectionId: collection.id,
				recipeId: recipe.id,
			});
		}

		if (onToggleInCollection) {
			onToggleInCollection(recipe.id);
		}
	};

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={`relative flex flex-col max-w-[235px] ${
				!isOnCarusel && 'w-[250px] min-[375px]:w-full '
			} ${isOnCarusel && 'w-full'} '2xl:w-[235px]' ${
				recipeOverEffect &&
				'bg-white shadow-card rounded-3xl shadow-sm shadow-gray-600 absolute z-20'
			}`}
			style={
				recipeOverEffect
					? {
							animationName: 'card-2',
							animationDuration: '0.6s',
							animationFillMode: 'forwards',
							animationTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
					  }
					: undefined
			}
		>
			{dontHaveAccessToRecipe && (
				<div className={`absolute top-2 left-2 p-2 rounded-full bg-white z-10`}>
					<img
						src={lockBlackIcon}
						alt="lock"
						className="w-4"
						data-cy="recipe-lock-icon"
					/>
				</div>
			)}

			<div data-cy="standard-recipe-card">
				{RenderVideoOrImage({
					isVideoFetched,
					mediaUrl,
					hasThumbnail,
					play,
					isVideo,
					handlePlayToggle: () => {
						handlePlayToggle();
						handlePlayClick();
					},
					recipeUuid: recipe.uuid,
					recipeTitle: recipe.title,
					chefFullName: `${recipe.chef?.user?.name || 'Unknown'} ${
						recipe.chef?.user?.last_name || 'Unknown'
					}`,
					onPlayClick: handlePlayClick,
					disableNavigation,
				})}
			</div>

			<CardInfo
				recipe={recipe}
				isChefProfile={isChefProfile}
				isChefPage={isChefPage}
				hideIcons={hideIcons}
				setIndexToFavouriteAndSaved={recipeOverEffect}
				dontHaveAccessToRecipe={dontHaveAccessToRecipe}
				paginationOptions={paginationOptions}
			/>

			{isChefProfile && (
				<ActionButtons
					buttonOneText="Edit"
					buttonTwoText="Delete"
					onButtonClickOne={() =>
						navigate(`/chef/profile/recipes/edit/${recipe.uuid}`)
					}
					onButtonClickTwo={onOpenModal}
				/>
			)}

			{collection && (
				<button
					onClick={handleToggleInCollection}
					data-cy={`collection-toggle-${recipe.id}`}
					className={`mt-2 ${
						isRecipeInCollection
							? 'bg-alertRed/70 hover:bg-alertRed'
							: 'bg-brandGreen hover:bg-emerald-400'
					} text-white font-bold py-2 px-4 rounded`}
				>
					{isRecipeInCollection
						? 'Remove from collection'
						: 'Add to collection'}
				</button>
			)}

			<PopUp open={open} onClose={onCloseModal} topCss={'20vh'}>
				<div className="flex flex-col gap-y-2 p-6 ">
					<p className="text-lg text-center text-slate-600 font-semibold my-4">
						Are you sure you want to delete this post?
					</p>
					<ActionButtons
						buttonOneText="No"
						buttonTwoText="Yes"
						onButtonClickOne={onCloseModal}
						onButtonClickTwo={() => {
							deleteRecipeMutation.mutate(recipe.uuid);
						}}
					/>
				</div>
			</PopUp>
		</div>
	);
};
