import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import creditCardBlackIcon from '@/assets/svg/credit-card-black-icon.svg';
import creditCardWhiteIcon from '@/assets/svg/credit-card-white-icon.svg';
import settingsBlackIcon from '@/assets/svg/general-settings-black-icon.svg';
import payWithCardBlackIcon from '@/assets/svg/pay-with-card-black-icon.svg';
import payWithCardWhiteIcon from '@/assets/svg/pay-with-card-white-icon.svg';
import preferencesBlackIcon from '@/assets/svg/preferences-black-icon.svg';
import preferencesWhiteIcon from '@/assets/svg/preferences-white-icon.svg';
import settingsWhiteIcon from '@/assets/svg/settings-withe.svg';

interface GeneralSettingsButtonsProps {
	selectConfigView: string;
	setSelectConfigView: React.Dispatch<React.SetStateAction<string>>;
	isCustomer: string | undefined;
	openBillingPortalAndNavigate: () => void;
	isChef: number | undefined;
	userHasHardCodedSubscription?: boolean;
}

export default function GeneralSettingsButtons({
	selectConfigView,
	setSelectConfigView,
	isCustomer,
	openBillingPortalAndNavigate,
	isChef,
	userHasHardCodedSubscription,
}: GeneralSettingsButtonsProps) {
	const [hoveredButton, setHoveredButton] = useState('');
	const navigate = useNavigate();

	const handleButtonClick = (view: string) => {
		setSelectConfigView(view);
	};

	const handleMouseEnter = (buttonType: string) => {
		setHoveredButton(buttonType);
	};

	const handleMouseLeave = () => {
		setHoveredButton('');
	};

	return (
		<div className="flex flex-wrap gap-x-4 gap-y-2">
			<button
				data-cy={`settings-general-settings-button`}
				className={` flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
					selectConfigView === 'GeneralSettings'
						? 'bg-brandOrange text-white hover:cursor-pointer'
						: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
				}`}
				onClick={() => handleButtonClick('GeneralSettings')}
				onMouseEnter={() => handleMouseEnter('GeneralSettings')}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={
						selectConfigView === 'GeneralSettings' ||
						hoveredButton === 'GeneralSettings'
							? settingsWhiteIcon
							: settingsBlackIcon
					}
					alt={`settings-${
						selectConfigView === 'GeneralSettings' ? 'white' : 'black'
					}-icon}`}
				/>
				<span>General Settings</span>
			</button>
			<button
				data-cy={`settings-preferences-button`}
				className={`flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
					selectConfigView === 'Preferences'
						? 'bg-brandOrange text-white hover:cursor-pointer'
						: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
				}`}
				onClick={() => handleButtonClick('Preferences')}
				onMouseEnter={() => handleMouseEnter('Preferences')}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={
						selectConfigView === 'Preferences' ||
						hoveredButton === 'Preferences'
							? preferencesWhiteIcon
							: preferencesBlackIcon
					}
					alt={`settings-${
						selectConfigView === 'Preferences' ? 'white' : 'black'
					}-icon}`}
				/>
				<span>Preferences</span>
			</button>

			{!userHasHardCodedSubscription && (
				<>
					{isCustomer && (
						<button
							data-cy={`settings-subscription-administrator-button`}
							className={`order-last min-[412px]:order-none flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
								selectConfigView === 'administrator'
									? 'bg-brandOrange text-white hover:cursor-pointer'
									: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
							}`}
							onClick={() => openBillingPortalAndNavigate()}
							onMouseEnter={() => handleMouseEnter('administrator')}
							onMouseLeave={handleMouseLeave}
						>
							<img
								src={
									selectConfigView === 'administrator' ||
									hoveredButton === 'administrator'
										? creditCardWhiteIcon
										: creditCardBlackIcon
								}
								alt={`settings-${
									selectConfigView === 'administrator' ? 'white' : 'black'
								}-icon}`}
							/>
							<span>Subscription Administrator</span>
						</button>
					)}
					{!isChef && (
						<button
							data-cy={'settings-paymentwall-button'}
							className={`order-last min-[412px]:order-none flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
								selectConfigView === 'Subscription'
									? 'bg-brandOrange text-white hover:cursor-pointer'
									: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
							}`}
							onClick={() => navigate('/subscriptions')}
							onMouseEnter={() => handleMouseEnter('Subscription')}
							onMouseLeave={handleMouseLeave}
						>
							<img
								src={
									selectConfigView === 'Subscription' ||
									hoveredButton === 'Subscription'
										? payWithCardWhiteIcon
										: payWithCardBlackIcon
								}
								alt={`settings-${
									selectConfigView === 'Subscription' ? 'white' : 'black'
								}-icon}`}
							/>
							<span>Subscription Plans</span>
						</button>
					)}
				</>
			)}
		</div>
	);
}
