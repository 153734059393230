export const CreatorsFormLandingPage = () => {
	return (
		<div
			data-cy="creators-form-landing-page"
			className="max-w-[1536px] min-h-[80vh] mx-auto flex flex-col gap-y-20 pt-12 lg:pt-0 lg:flex-row lg:items-center lg:justify-center"
		>
			<div className="lg:basis-1/2">
				<div className="mx-auto space-y-4 px-4 sm:px-10 lg:w-[600px]">
					<h3 className="text-4xl sm:text-7xl pb-4 font-semibold text-brandRedStrong tracking-widest">
						Want to be a Kitched creator?
					</h3>
					<p className="text-xl text-[#6a0136]">
						Empower your brand. Grow with a community of creators and maximise
						the earnings from your digital content. All with our Kitched
						technology, making it simple and easy.
					</p>
				</div>
			</div>
			<div className="px-4 sm:grid sm:place-content-center text-white lg:basis-1/2">
				<div className="space-y-4 bg-brandRedStrong rounded-xl shadow-md py-20 min-[440px]:px-10 mb-4">
					<p className="text-2xl text-center sm:text-left min-[440px]:text-4xl text-white">
						Contact us at:
					</p>
					<p className="text-2xl text-center sm:text-left min-[440px]:text-4xl text-white">
						lianna@kitched.com
					</p>
				</div>
			</div>
		</div>
	);
};
