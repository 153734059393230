import { Outlet, useLocation } from 'react-router-dom';

import { Footer } from '@/components/footer/Footer';
import { BackgroundVideoSection } from '@/components/home/BackgroundVideoSection';
import useWindowWidth from '@/hooks/useWindowWidth';
import AnalyticsTracker from '@/service/analytics/AnalyticsTracker';

import Navbar from '@components/navbar/Navbar';

export default function Root() {
	const location = useLocation();
	const windowWidth = useWindowWidth();

	return (
		<div className="flex flex-col items-center justify-center relative">
			<header
				className={`w-full ${
					windowWidth < 640 && location.pathname == '/home' && 'mb-10'
				}`}
			>
				<Navbar />

				{location.pathname == '/home' && windowWidth > 640 && (
					<BackgroundVideoSection />
				)}
			</header>
			<main
				className={`w-full min-h-screen ${
					windowWidth < 640 && location.pathname == '/home' && 'mt-12'
				}`}
			>
				<AnalyticsTracker />
				<Outlet />
			</main>
			{location.pathname === '/early-user' ? <div></div> : <Footer />}
		</div>
	);
}
