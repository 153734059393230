import { SwiperSlide } from 'swiper/react';

import { Loader } from '@/components/common/Loader';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { Slider } from '@/components/common/slider/Slider';
import { useGetMostLikedRecipes } from '@/hooks/recipes/useGetMostLikedRecipes';
import { RecipeSort } from '@/interface/filter.interface';

export const LandingRecipesSlider = () => {
	const skip = 0;
	const take = 24;
	const sort = RecipeSort.LATEST;
	const mockUserSuscription = true;

	const {
		mostLikedRecipes,
		errorMostLikedRecipes,
		isLoadingMostLikedRecipes,
		totalEntities,
	} = useGetMostLikedRecipes({ take, skip, sort });

	if (isLoadingMostLikedRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (errorMostLikedRecipes) {
		return (
			<div className="flex justify-center text-2xl items-center">
				<p>{errorMostLikedRecipes?.message}</p>
			</div>
		);
	}

	return (
		<div data-cy="recipes-section" className="w-full">
			{(totalEntities as number) === 0 ? (
				<div className="px-8 mt-6">
					<RecipeDinamicCardSkeletond
						height="normal"
						message="No recipes found"
					/>
				</div>
			) : (
				<div style={{ pointerEvents: 'none' }}>
					<div className="flex justify-center">
						<Slider cardsType={{ type: 'SMALL' }}>
							{mostLikedRecipes?.data.map((recipes) => (
								<SwiperSlide key={recipes.id}>
									<RecipesCard
										recipe={recipes}
										isOnCarusel={true}
										userHasSubscription={mockUserSuscription}
									/>
								</SwiperSlide>
							))}
						</Slider>
					</div>
				</div>
			)}
		</div>
	);
};
