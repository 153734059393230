import { UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';

import { openBillingPortal } from '../../service/api/payments';

import { subscriptionPlans } from '@/assets/mock/subscription-plans';
import { useAuth } from '@/context/useAuth';
import { notifyError, notifyInfo } from '@/helper/notifications';
import {
	CustomerDto,
	createPaymentSessionDto,
} from '@/interface/payment.interfaces';
import { SuscriberPlanEnum } from '@/interface/subscription.interfaces';

import checkedBoxBlackIcon from '@assets/svg/checked-black-icon.svg';
import checkedBoxWhiteIcon from '@assets/svg/checked-white-icon.svg';

interface PaymentWallComponentProps {
	createPaymentSessionMutation: UseMutationResult<
		{ sessionUrl: string },
		unknown,
		createPaymentSessionDto
	>;
	customerDto: CustomerDto;
}

export const PaymentWallComponent = ({
	createPaymentSessionMutation,
	customerDto,
}: PaymentWallComponentProps) => {
	const { isPending } = createPaymentSessionMutation;
	const [selectedPlan, setSelectedPlan] = useState('');
	const { user } = useAuth();
	const activePlan = user?.subscription?.activePlan;

	const openBillingPortalAndNavigate = async () => {
		try {
			const { url } = await openBillingPortal();
			window.open(url, '_blank', 'noopener,noreferrer');
		} catch (error) {
			notifyError(`${error}`);
		}
	};

	const handleSelectPlan = (
		suscriberPlan: SuscriberPlanEnum,
		selectedPlan: string,
	) => {
		setSelectedPlan(selectedPlan);
		createPaymentSessionMutation.mutate({
			customerDto,
			suscriberPlan,
		});
	};

	return (
		<>
			{subscriptionPlans.map((plan) => {
				const suscriberPlan =
					plan.param === 'essential'
						? SuscriberPlanEnum.ESSENTIAL
						: SuscriberPlanEnum.PRO;

				return (
					<div
						data-cy={`${plan.param}-plan-card`}
						key={plan.name}
						className={`relative flex flex-col w-[300px] ${plan.textColor} rounded-3xl p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10`}
						style={{ backgroundImage: `url(${plan.bgColor})` }}
					>
						{activePlan === plan.param && (
							<div className="absolute -top-2 -right-2 px-2 py-4 rounded-full bg-green-500 ring-1 ring-white text-white">
								Active
							</div>
						)}

						<div className="flex flex-col gap-x-4">
							<h3 className="text-base font-semibold leading-7">{plan.name}</h3>
							<div className="flex items-baseline gap-x-2">
								<span className="text-5xl font-bold tracking-tight">
									{plan.price}
								</span>
								<span className="text-base font-semibold leading-7">
									{plan.period}
								</span>
							</div>
							{plan.freeTrial && (!user?.hadFreeTrial as boolean) && (
								<p className="text-sm leading-6 ">¡Free trial for 7 days!</p>
							)}
						</div>

						<ul role="list" className="mt-6 space-y-3 text-sm leading-6">
							{plan.features.map((feature, index) => (
								<li key={index} className="flex gap-x-3">
									<img
										src={
											plan.name === 'Basic'
												? checkedBoxBlackIcon
												: checkedBoxWhiteIcon
										}
										alt="checked-icon"
									/>
									{feature}
								</li>
							))}
						</ul>

						<div className="flex-1"></div>

						{activePlan === plan.param ? (
							<button
								data-cy={`${plan.param}-plan-button`}
								className={`rounded-3xl bg-white px-3.5 py-5 mt-8 text-center font-semibold leading-6 ${plan.buttonTextColor} shadow-sm border border-white transition-transform hover:scale-105`}
								onClick={openBillingPortalAndNavigate}
							>
								Unsubscribe
							</button>
						) : (
							(plan.param === 'essential' && (
								<button
									data-cy={`${plan.param}-plan-button`}
									className={`${
										plan.name === 'Basic' && 'hidden'
									} block rounded-3xl bg-white px-3.5 py-5 mt-8 text-center font-semibold leading-6 ${
										plan.buttonTextColor
									} shadow-sm border border-white transition-transform hover:scale-105`}
									onClick={() => handleSelectPlan(suscriberPlan, plan.param)}
								>
									{isPending && selectedPlan === suscriberPlan
										? 'Processing...'
										: 'Get Started'}
								</button>
							)) ||
							(plan.param === 'pro' && (
								<button
									data-cy={`${plan.param}-plan-button`}
									className={`${
										plan.name === 'Basic' && 'hidden'
									} block rounded-3xl bg-white px-3.5 py-5 mt-8 text-center font-semibold leading-6 ${
										plan.buttonTextColor
									} shadow-sm border border-white transition-transform hover:scale-105`}
									onClick={() => notifyInfo('The pro plan is coming soon')}
								>
									Coming soon
								</button>
							))
						)}
					</div>
				);
			})}
		</>
	);
};
