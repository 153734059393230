import { EventInput } from '@fullcalendar/core/index.js';
import { useState } from 'react';

import { generateUniqueEventId } from './calendarFunctions';

import crossIcon from '@/assets/svg/cross.svg';
import { PopUp } from '@/components/common/PopUp';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import { useDeleteMealPlan } from '@/hooks/meal-plan/useDeleteMealPlan';

interface DeleteMealPlanFormProps {
	id: number;
	selectedEvent: EventInput;
	setEvents: React.Dispatch<React.SetStateAction<EventInput[]>>;
}

export const DeleteMealPlanForm = ({
	id,
	selectedEvent,
	setEvents,
}: DeleteMealPlanFormProps) => {
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const { deleteMealPlan, isDeletingMealPlan } = useDeleteMealPlan();

	const handleModalState = () => setOpenConfirmModal(!openConfirmModal);

	const handleConfirmDeleteMealPlan = () => {
		deleteMealPlan(id, {
			onSuccess: () => {
				const deletedEvent = {
					id: generateUniqueEventId(),
					title: '',
					start: selectedEvent.event.start,
				};

				setEvents((prevEvents) =>
					prevEvents.map((event) =>
						event.id === selectedEvent.event.id ? deletedEvent : event,
					),
				);
				handleModalState();
			},
		});
	};

	return (
		<article>
			<button
				className="p-1 w-5 disabled:cursor-not-allowed rounded-full hover:bg-friendlyRed/50 "
				onClick={() => {
					handleModalState();
				}}
				disabled={isDeletingMealPlan}
				data-cy="delete-meal-plan"
			>
				<img src={crossIcon} alt="cross" />
			</button>
			<PopUp open={openConfirmModal} onClose={handleModalState} topCss="10vh">
				<div className="flex flex-col gap-y-2 p-6">
					<p className="text-lg text-center text-slate-600 font-semibold my-4">
						Are you sure you want to delete this meal plan?
					</p>
					<ActionButtons
						buttonOneText="Yes"
						buttonTwoText="No"
						onButtonClickOne={handleConfirmDeleteMealPlan}
						onButtonClickTwo={handleModalState}
					/>
				</div>
			</PopUp>
		</article>
	);
};
