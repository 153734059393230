import { useEffect, useState } from 'react';

import { Loader } from '../common/Loader';
import { NavigationButtons } from '../common/buttons/navigation-button/NavigationButtons';
import { RecipesCard } from '../common/recipe/recipe-card/RecipesCard';

import { usePagination } from '@/hooks/usePagination';
import { useGetUserSavedRecipes } from '@/hooks/user/useGetUserSavedRecipes';
import { IRecipe } from '@/interface/recipe.interfaces';

interface SavedRecipePopUpProps {
	recipeSelected?: number[];
	setRecipesSelected: (ids: number[]) => void;
}

export const SavedRecipePopUp = ({
	recipeSelected = [],
	setRecipesSelected,
}: SavedRecipePopUpProps) => {
	const [selectedRecipes, setSelectedRecipes] =
		useState<number[]>(recipeSelected);
	const [skip, setSkip] = useState(0);
	const take = 6;

	const {
		userSavedRecipes,
		isLoadingUserSavedRecipes,
		totalUserSavedEntities,
		refetchUserSavedRecipes,
		errorUserSavedRecipes,
	} = useGetUserSavedRecipes({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: totalUserSavedEntities,
			setSkip,
		});

	useEffect(() => {
		refetchUserSavedRecipes();
	}, [skip]);

	useEffect(() => {
		if (setRecipesSelected && selectedRecipes !== null) {
			setRecipesSelected(selectedRecipes);
		}
	}, [selectedRecipes, setRecipesSelected]);

	const toggleRecipeSelection = (id: number) => {
		setSelectedRecipes((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((recipeId) => recipeId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	if (isLoadingUserSavedRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (errorUserSavedRecipes) return <></>;

	return (
		<div>
			{userSavedRecipes && userSavedRecipes.data.length > 0 ? (
				<div>
					<div
						data-cy="saved-recipes-section"
						className="flex flex-wrap justify-center xl:mx-10 mt-10 relative"
						id="saved-recipes"
					>
						{userSavedRecipes.data.map((savedRecipe: IRecipe) => (
							<div
								key={savedRecipe.id}
								className={`border p-4 rounded-lg cursor-pointer w-full max-w-[235px] ${
									selectedRecipes.includes(savedRecipe.id)
										? 'bg-blue-100'
										: 'bg-white'
								}`}
								onClick={() => {
									toggleRecipeSelection(savedRecipe.id);
								}}
								data-cy={`user-card-saved-recipes-${savedRecipe.id}`}
							>
								<div
									className="w-full flex flex-wrap justify-center gap-5 md:gap-6 lg:gap-14 mb-14"
									style={{ pointerEvents: 'none' }}
								>
									<RecipesCard
										recipe={savedRecipe}
										hideIcons={true}
										userHasSubscription={true}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="pt-4 flex justify-center items-center">
						<NavigationButtons
							currentPage={currentPage}
							totalPages={totalPages}
							handleNextClick={onHandleNext}
							handlePrevClick={onHandlePrevious}
						/>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center h-full">
					<h3 className="text-xl md:text-2xl font-semibold tracking-[-0.04em] text-center p-8 w-[350px]">
						You do not have any saved recipes yet. Please save a recipe first so
						you can add it to your meal plans.
					</h3>
				</div>
			)}
		</div>
	);
};
