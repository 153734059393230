import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { TagsSearchBar } from '../common/recipe/TagsSearchBar';

import selectFromMenuWhiteIcon from '@/assets/svg/select-from-menu-white.svg';
import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { TagsSearchMenuPopUp } from '@/components/common/pop-up/TagsPopUp';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { Slider } from '@/components/common/slider/Slider';
import { useGetAllRecipesByDate } from '@/hooks/recipes/useGetAllRecipesByDate';
import { usePagination } from '@/hooks/usePagination';
import { OrderByDate } from '@/service/api/recipe';

interface IRecipesForYouProps {
	userHasSubscription?: boolean;
}

export const RecipesForYou = ({ userHasSubscription }: IRecipesForYouProps) => {
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);
	const [skip, setSkip] = useState(0);
	const [tags, setTags] = useState<string>('');
	const [sliderStyle, setSliderStyle] = useState<string | null>(null);

	const take = 18;
	const {
		recipesByDate,
		totalEntities,
		isLoadingRecipesByDate,
		isErrorRecipesByDate,
		refetchRecipesByDate,
	} = useGetAllRecipesByDate(OrderByDate.DESC, { take, skip, tags });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	const onHandleSearchTag = (tags: string) => {
		setTags(tags);
	};

	useEffect(() => {
		refetchRecipesByDate();
	}, [skip, tags]);

	if (isLoadingRecipesByDate)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (isErrorRecipesByDate) return <></>;

	return (
		<div
			data-cy="recipesforyou-section"
			className="w-full xl:pt-0"
			id="favourite-recipes"
		>
			<div className="flex flex-col gap-y-3 px-5">
				<div className="flex flex-col items-start gap-y-3 min-[500px]:flex-row min-[500px]:justify-between min-[500px]:items-center">
					<Title
						textTitle="Recipes"
						colorText="brandRed"
						titleWithColor="for you"
					/>
					<button
						data-cy="recipeforyou-tag-button"
						className="place-self-end w-[45%] min-[430px]:w-[35%] sm:w-[180px] flex justify-between items-center py-4 px-4 text-white leading-tight bg-brandOrange rounded-full"
						onClick={() => setOpen(true)}
					>
						<div className="flex justify-between items-center gap-2 w-full h-full">
							<p className="text-md font-semibold">All Filters</p>
							<img
								src={selectFromMenuWhiteIcon}
								alt="select from menu icon"
								className="ml-1 mt-[2px]"
							/>
						</div>
					</button>
				</div>

				<TagsSearchBar
					onHandleSearchTagByQuery={onHandleSearchTag}
					sectionName={'recipes-for-you'}
				/>
			</div>

			{(totalEntities as number) === 0 ? (
				<div className="px-8 mt-6 ">
					<RecipeDinamicCardSkeletond
						height="normal"
						message="No recipes found"
					/>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center lg:flex-row lg:items-center lg:gap-x-4">
					<Slider cardsType={{ type: 'SMALL' }}>
						{recipesByDate?.map((recipes) => (
							<SwiperSlide
								key={recipes.id}
								className={sliderStyle == recipes.uuid ? 'z-10' : 'z-0'}
							>
								<RecipesCard
									recipe={recipes}
									setSliderStyle={setSliderStyle}
									userHasSubscription={userHasSubscription}
									isOnCarusel={true}
								/>
							</SwiperSlide>
						))}
					</Slider>
					<div className="lg:-translate-y-20">
						<NavigationButtons
							currentPage={currentPage}
							totalPages={totalPages}
							handleNextClick={onHandleNext}
							handlePrevClick={onHandlePrevious}
						/>
					</div>
				</div>
			)}

			<TagsSearchMenuPopUp
				onHandleSearchTag={onHandleSearchTag}
				open={open}
				onCloseModal={onCloseModal}
			/>
		</div>
	);
};
