import { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Button } from './components/Button';

import useWindowWidth from '@/hooks/useWindowWidth';

import chefImage from '@assets/image/landing-page-chef.png';
import searchPageContent from '@assets/image/landing-page-search-content.png';
import mealPlannerImage from '@assets/image/meal-planner-landing.png';

export const ContentDetailsLandingPage = () => {
	const [animationState, setAnimationState] = useState({
		first: false,
		second: false,
		third: false,
	});
	const windowWidth = useWindowWidth();

	const handleAfterAnimatedIn = (key: string) => {
		setAnimationState((prevState) => ({
			...prevState,
			[key]: true,
		}));
	};

	return (
		<div
			className="flex flex-col justify-center items-center gap-20 bg-gradient-to-b from-brandBeigeStrong to-white px-[5vw] py-[80px] "
			data-cy="kitched-details-section"
		>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center h-auto gap-4"
				data-cy="kitched-details"
			>
				<div className="flex justify-start items-center basis-1/2 lg:basis-[40%] h-full order-2 md:order-1 w-full md:w-auto">
					<div className="bg-brandRedStrong p-8 rounded-xl w-full md:w-auto h-full">
						<img
							src={searchPageContent}
							alt=""
							className="w-auto h-full object-contain"
						/>
					</div>
				</div>

				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%] order-1 md:order-2  ${
						animationState.first ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('first');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						EASY SEARCH, SAVE AND FILTER
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono">
						STOP SCROLLING. START COOKING.
					</h1>
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136]">
						We specialise in recipe content and like you, we love reels,
						voiceovers and relatable creators. Our search, filter and save
						feature saves you time and endless scrolling.
					</p>
					<Button text="See more" redirectTo="/signup" />
				</ScrollAnimation>
			</div>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center md:h-[500px] gap-4"
				data-cy="kitched-details"
			>
				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%]  ${
						animationState.second ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('second');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						EASY & FUNCTIONAL
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono">
						TRUSTED RECIPE CONTENT
					</h1>
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136]">
						We are a new type of recipe platform.Our recipes are directly from
						well known, trustworthy social media recipe creators. Kitched is
						here to filter out the noise and make it easy to cook from content
						you love.
					</p>
					<Button text="See more" redirectTo="/signup" />
				</ScrollAnimation>
				<div className="flex justify-center  items-center md:justify-end basis-1/2 lg:basis-[40%]  w-full md:w-auto h-full">
					<div className="flex justify-center bg-brandRedStrong p-8 rounded-xl w-[70%] h-[500px] md:w-full md:h-full ">
						<img
							src={chefImage}
							alt=""
							className="w-auto h-full object-contain"
						/>
					</div>
				</div>
			</div>
			<div
				className="flex flex-col justify-start items-start  md:flex-row  md:justify-center md:items-center md:h-[500px] gap-4 "
				data-cy="kitched-details"
			>
				<ScrollAnimation
					className={`flex flex-col justify-between items-start gap-6 basis-1/2 lg:basis-[60%] order-1 md:order-2  ${
						animationState.third ? 'opacity-100' : 'opacity-0'
					}`}
					animateIn={
						windowWidth >= 768 ? 'animate-fadeInUp' : 'animate-fade-in-left'
					}
					animateOnce={true}
					afterAnimatedIn={() => {
						handleAfterAnimatedIn('third');
						return undefined;
					}}
				>
					<span className="text-brandRedStrong text-sm font-medium font-verdana">
						PLAN YOUR WEEK, CURATE RECIPE COLLECTIONS
					</span>
					<h1 className="text-brandRedStrong text-[28px] min-[991px]:text-[45px]  font-semibold font-robotoMono md:leading-10 lg:leading-normal">
						PERSONALISED PLANNER AND RECIPE COLLECTIONS
					</h1>
					<p className="font-verdana font-normal text-[20px] leading-5 text-[#6a0136]">
						Plan your weekly cooking and avoid a last minute expensive takeaway
						order. Curate go-to collections based on what you love to cook.
						Plus, you can invite others to collaborate, sharing your menu with
						with family and friends.
					</p>
					<Button text="See more" redirectTo="/signup" />
				</ScrollAnimation>
				<div className="flex justify-center  items-center md:justify-start basis-1/2 lg:basis-[40%] order-2 md:order-1 h-full w-full md:w-auto ">
					<div className="flex justify-center bg-brandRedStrong p-8 rounded-xl w-[70%] h-[500px] md:w-full md:h-full ">
						<img
							src={mealPlannerImage}
							alt=""
							className="w-auto h-full object-contain"
							data-cy="kitched-meal-image"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
