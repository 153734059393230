interface StatItemProps {
	number: string;
	description: string;
}

const StatItem = ({ number, description }: StatItemProps) => (
	<div
		className="text-center transition duration-300 ease-in-out transform hover:scale-105 hover:text-brandBeigeStrong"
		data-cy="stats-section-item"
	>
		<div className="text-2xl sm:text-3xl md:text-4xl font-bold mb-1 sm:mb-2">
			{number}
		</div>
		<div className="text-xs sm:text-sm">{description}</div>
	</div>
);

export const StatsSection = () => {
	return (
		<div className="bg-brandRedStrong py-5 sm:py-7 font-robotoMono">
			<div className="container mx-auto px-4">
				<h2
					className="text-white text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6 sm:mb-8"
					data-cy="stats-section-title"
				>
					WE HAVE DONE THE HARD PART FOR YOU
				</h2>
				<div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 text-white">
					<StatItem number="25+" description="Experienced creators" />
					<StatItem number="50+" description="Filters & Dietary Options" />
					<StatItem number="1000s" description="High-quality recipes" />
					<StatItem number="20+" description="Cuisines" />
				</div>
			</div>
		</div>
	);
};
