import { Link } from 'react-router-dom';

interface IButtonProps {
	text: string;
	redirectTo: string;
}

export const Button = ({ text, redirectTo }: IButtonProps) => {
	return (
		<Link
			className="bg-brandRedStrong text-white py-2.5 px-6 rounded-xl text-xl hover:bg-black transition duration-700"
			to={redirectTo}
			data-cy="redirect-button"
		>
			{text}
		</Link>
	);
};
