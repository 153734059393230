import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import hidePasswordImg from '@/assets/svg/hide-password.svg';
import showPasswordImg from '@/assets/svg/show-password.svg';
import { Loader } from '@/components/common/Loader';
import { SocialIcons } from '@/components/sign-up/social-icons/SocialIcons';
import { TitleLogin } from '@/components/sign-up/title/TitleLogin';
import { useAuth } from '@/context/useAuth';
import {
	LOGIN_COMMON_ERROR,
	LOGIN_SUCCESSFUL,
	NOT_CONFIRMED_USER_COGNITO_ERROR,
	NOT_CONFIRMED_USER_ERROR,
	USER_NOT_AUTHORIZED_COGNITO_ERROR,
	USER_NOT_EXISTS,
} from '@/error/authentication.notify.messages';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { loginValidationSchema } from '@/helper/validations/login.validations';

export const Form = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const validationSchema = loginValidationSchema();
	const [showPassword, setShowPassword] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			rememberMe: false,
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await login(values);
				notifySuccess(LOGIN_SUCCESSFUL);
				navigate('/home');
			} catch (error: unknown) {
				let message = LOGIN_COMMON_ERROR;
				if (error instanceof Error) {
					message = error.message;
					if (error.message === NOT_CONFIRMED_USER_COGNITO_ERROR)
						message = NOT_CONFIRMED_USER_ERROR;
					if (error.message === USER_NOT_AUTHORIZED_COGNITO_ERROR)
						message = USER_NOT_EXISTS;
				}
				notifyError(message);
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<div className="w-full h-[509px] mt-[114px] mb-[147px] flex flex-col ">
			<TitleLogin />

			<SocialIcons />

			<form
				className="flex flex-col gap-[42px] h-[252px] max-w-lg mx-auto   mt-12"
				onSubmit={formik.handleSubmit}
			>
				<div className="gap-[16px] h-[158px]">
					<div className="flex flex-col gap-[16px] w-[320px] h-[122px]">
						<div className="relative">
							<input
								data-cy="login-input-email"
								type="email"
								name="email"
								placeholder="Enter your email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="email">EMAIL</label>
							</div>

							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.email && formik.errors.email && (
									<div>{formik.errors.email}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<div className="flex">
								<input
									data-cy="login-input-password"
									type={showPassword ? 'text' : 'password'}
									name="password"
									placeholder="Enter your password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50 pr-10"
								/>
								<button
									type="button"
									onClick={() => setShowPassword(!showPassword)}
									className="absolute right-3 top-1/2 transform -translate-y-1/2"
								>
									<img
										src={showPassword ? showPasswordImg : hidePasswordImg}
										alt="password-icon"
										className="w-[20px] h-[20px]"
									/>
								</button>
							</div>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="password">PASSWORD</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.password && formik.errors.password && (
									<div>{formik.errors.password}</div>
								)}
							</div>
						</div>
					</div>

					<div className="flex items-center gap-1 mt-5 w-[320px] h-5">
						<input
							data-cy="login-checkbox"
							type="checkbox"
							name="rememberMe"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.rememberMe}
							className="w-5 h-4 rounded-sm accent-brandOrange"
						/>
						<div className="flex justify-between flex-1">
							<label
								htmlFor="rememberMe"
								className="text-[13px] -mt-[3px] text-gray-90"
							>
								Remember Me
							</label>
						</div>

						<div className="flex flex-col gap-1.5">
							<Link
								to="/forgot-password"
								data-cy="forgot-password-link"
								className="text-[12px] -mt-[3px] cursor-pointer text-brandOrange hover:underline"
							>
								Forgot password?
							</Link>
							<Link
								to="/resend-verification"
								data-cy="resend-verification-link"
								className="text-[12px] -mt-[3px] cursor-pointer text-brandOrange hover:underline"
							>
								Resend verification
							</Link>
						</div>
					</div>
				</div>

				<button
					data-cy="login-submit"
					type="submit"
					className="w-[156px] h-[52px] rounded-full bg-brandOrange text-white flex justify-center items-center mx-auto  transition-all hover:text-brandOrange hover:bg-white hover:ring-2 hover:ring-brandOrange hover:cursor-pointer"
					disabled={formik.isSubmitting}
					onSubmit={formik.submitForm}
				>
					{formik.isSubmitting ? (
						<Loader size={1.4} />
					) : (
						<span className="text-[15px] font-semibold">Log In</span>
					)}
					<span className="p-[4px] bg-brandOrange rounded-full border-2 border-white absolute ml-36">
						<img src={arrowUpRight} alt="arrow-icon" width="24" />
					</span>
				</button>
			</form>

			<div className="flex items-center pt-10">
				<p className="p-2 md:p-0 md:mr-1 text-sm md:text-md text-loginTextGrey">
					Don’t have an account yet?
				</p>
				<Link
					to="/signup"
					data-cy="sign-up-redirect"
					className="text-sm md:text-md cursor-pointer text-brandOrange hover:underline"
				>
					Create account
				</Link>
			</div>
		</div>
	);
};
