import { useState } from 'react';
import { Link } from 'react-router-dom';

import starIcon from '@/assets/svg/star-icon.svg';

export const UpgradePlanButton = () => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div className="flex">
			<Link
				to="/subscriptions/message"
				className={`
          flex items-center justify-center gap-2
          font-semibold text-white rounded-full transition-all duration-300
          bg-gradient-to-r from-brandRed to-orange-500
          hover:from-red-600 hover:to-orange-600
          transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandRed
          w-8 h-8 xl:w-auto xl:h-auto xl:px-4 xl:py-1.5
        `}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<span className="hidden xl:inline">Start free trial</span>
				<img
					src={starIcon}
					alt="Upgrade"
					className={`w-4 h-4 xl:w-3.5 xl:h-3.5 transition-all duration-300 ${
						isHovered ? 'rotate-12 scale-110' : ''
					}`}
				/>
			</Link>
		</div>
	);
};
