import greenLogo from '@/assets/svg/navbar-logo-green.svg';

function handleRedirect(location: string) {
	if (location == '/home') {
		window.location.href = '/recipes';
	} else {
		window.location.href = '/home';
	}
}

export const ErrorBoundaryFallback = ({
	noAuthRoutes,
}: {
	noAuthRoutes?: boolean;
}) => {
	const pathname = window.location.pathname;

	return (
		<div className="flex flex-col" data-cy="error-boundary-container">
			<img
				src={greenLogo}
				alt="error-boundary-logo"
				className="w-[30%] h-[30%] md:w-[20%] md:h-[20%] mt-4 ml-4"
			/>
			<div
				className="flex mt-20 items-center flex-col w-full h-screen gap-6"
				data-cy="not-found-page-container"
			>
				<h1 className="text-black text-2xl sm:text-3xl text-center p-8   font-sans font-bold tracking-tighter text-wrap">
					Oops, something went wrong! The technical team is on it.
				</h1>
				{!noAuthRoutes && (
					<button
						onClick={() => handleRedirect(pathname)}
						className="rounded-full bg-brandRed px-4 py-2 font-sans font-medium"
					>
						{pathname == '/home' ? 'Explore recipes' : 'Go back home'}
					</button>
				)}
			</div>
		</div>
	);
};
