import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import { User } from '@/context/interfaces';
import { USER_ERRORS_MESSAGES } from '@/error/user-errors.enum';
import { IGetAllResponse } from '@/interface/get-all-response';
import { IPagination } from '@/interface/pagination.interface';
import { IRecipe } from '@/interface/recipe.interfaces';
import { CreateUserDto, UserPreferences } from '@/interface/user.interfaces';
import Api from '@/service';

const RESOURCE = 'user';

interface UpdateUserPreferencesProps {
	userId: number;
	newPreferences: UserPreferences;
}

export const getUser = async (token: string): Promise<User> => {
	try {
		const result = await Api.get<User>(`/${RESOURCE}/me`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return result.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.NOT_FOUND);
	}
};

export const getAllUsers = async (
	take: number,
	skip: number,
): Promise<IGetAllResponse<User>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<User>>(
			`/${RESOURCE}/referred-chefs`,
			{
				params: { take: take, skip: skip },
			},
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.GET_ALL_USER_ERROR);
	}
};

export const createUser = async (
	user: CreateUserDto,
): Promise<CreateUserDto> => {
	try {
		const result = await Api.post('/auth/register', user);
		return result.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.CREATE_USER_ERROR);
	}
};

export const fetchUserByEmail = async (email: string): Promise<User | null> => {
	try {
		const { data } = await Api.get<User>(
			`${import.meta.env.VITE_API_BASE_URL}/user/email/${email}`,
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.GET_USER_BY_EMAIL_ERROR);
	}
};

export const fetchPendingUsers = async (): Promise<User[]> => {
	try {
		const { data } = await Api.get<User[]>(`/${RESOURCE}/pendings`);

		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.GET_PENDING_USERS_ERROR);
	}
};

export const getUserFavouritesAndSavedRecipes = async (): Promise<User> => {
	try {
		const { data } = await Api.get<User>(`/${RESOURCE}/portfolio`);

		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(
			USER_ERRORS_MESSAGES.GET_USER_FAVOURITE_AND_SAVE_RECIPES_ERROR,
		);
	}
};

export const editUser = async ({
	userId,
	newProfileImage,
	newUserInfo,
}: {
	userId: number;
	newProfileImage?: File;
	newUserInfo?: { name: string; last_name: string };
}): Promise<User> => {
	try {
		const formData = new FormData();
		if (newProfileImage) {
			formData.append('image', newProfileImage);
		}
		if (newUserInfo) {
			formData.append('name', newUserInfo.name);
			formData.append('last_name', newUserInfo.last_name);
		}

		const { data } = await Api.put<User>(`${RESOURCE}/${userId}`, formData);

		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const handleUserRequestToBeChef = async ({
	userId,
	acceptOrDenyChef,
}: {
	userId: number;
	acceptOrDenyChef: boolean;
}): Promise<User> => {
	try {
		const { data } = await Api.put<User>(
			`/${RESOURCE}/${userId}/handle/chef/application`,
			{ acceptOrDenyChef },
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_ACCEPT_CHEF_REQUEST_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getUserById = async (id: number): Promise<User> => {
	try {
		const { data } = await Api.get<User>(`/${RESOURCE}/${id}`);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.GET_USER_BY_ID_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const putUserAddSavedRecipe = async (
	userId: number,
	recipeId: number,
): Promise<User> => {
	try {
		const { data } = await Api.put<User>(
			`/${RESOURCE}/${userId}/save/recipe/${recipeId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_SAVE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const deleteUserSavedRecipe = async (
	userId: number,
	recipeId: number,
): Promise<User> => {
	try {
		const { data } = await Api.delete<User>(
			`/${RESOURCE}/${userId}/save/recipe/${recipeId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.DELETE_USER_SAVE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const putUserAddFavouriteRecipe = async (
	userId: number,
	recipeId: number,
): Promise<User> => {
	try {
		const { data } = await Api.put<User>(
			`/${RESOURCE}/${userId}/favorite/recipe/${recipeId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_FAVOURITE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const deleteUserFavouriteRecipe = async (
	userId: number,
	recipeId: number,
): Promise<User> => {
	try {
		const { data } = await Api.delete<User>(
			`/${RESOURCE}/${userId}/favorite/recipe/${recipeId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.DELETE_USER_FAVOURITE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const putUserFollowChef = async (
	userId: number,
	chefId: number,
): Promise<User> => {
	try {
		const { data } = await Api.put<User>(
			`/${RESOURCE}/${userId}/follow/chef/${chefId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_FOLLOW_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const deleteUserFollowChef = async (
	userId: number,
	chefId: number,
): Promise<User> => {
	try {
		const { data } = await Api.delete<User>(
			`/${RESOURCE}/${userId}/unfollow/chef/${chefId}`,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.DELETE_USER_FOLLOW_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const editUserPreferences = async ({
	userId,
	newPreferences,
}: UpdateUserPreferencesProps): Promise<User> => {
	try {
		const { data } = await Api.put<User>(
			`/${RESOURCE}/${userId}/preferences`,
			newPreferences,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			USER_ERRORS_MESSAGES.UPDATE_USER_PREFERENCES,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getUserLikedRecipes = async (
	options: IPagination,
): Promise<IGetAllResponse<IRecipe>> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/liked-recipes`, {
			params: {
				take: options.take,
				skip: options.skip,
				sort: options.sort,
			},
		});

		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.GET_USER_LIKED_RECIPES_ERROR);
	}
};

export const getUserSavedRecipes = async (
	options: IPagination,
): Promise<IGetAllResponse<IRecipe>> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/saved-recipes`, {
			params: {
				take: options.take,
				skip: options.skip,
				sort: options.sort,
			},
		});

		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(USER_ERRORS_MESSAGES.GET_USER_SAVED_RECIPES_ERROR);
	}
};
