import { useMutation } from '@tanstack/react-query';

import { notifyError } from '@/helper/notifications';
import { createPaymentSession } from '@/service/api/payments';

export const useCreatePaymentSession = () => {
	const createPaymentSessionMutation = useMutation({
		mutationFn: createPaymentSession,
		onSuccess: (data) => {
			const sessionUrl = data?.sessionUrl;
			if (sessionUrl) {
				window.location.href = sessionUrl;
			}
		},
		onError: (error) => {
			notifyError(`${error}`);
		},
	});

	return { createPaymentSessionMutation };
};
