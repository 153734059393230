import { useEffect, useState } from 'react';

import { CollectionHeaderMenu } from './CollectionHeaderMenu';
import { EditCollectionModal } from './EditCollectionModal';

import addIcon from '@/assets/svg/add-icon.svg';
import goBackArrowIcon from '@/assets/svg/go-back-arrow.svg';
import { useDeleteCollection } from '@/hooks/collection/useDeleteCollection';
import { useUpdateCollection } from '@/hooks/collection/useUpdateCollection';
import { ICollection } from '@/interface/collection.interfaces';
import { DeleteCollectionConfirmModal } from '@/pages/user/collections/header/DeleteCollectionConfirmModal';
import { SelectRecipeCollectionModal } from '@/pages/user/collections/header/SelectRecipeCollectionModal';

interface CollectionHeaderProps {
	collection: ICollection;
	onBack: () => void;
}

export const CollectionHeader = ({
	collection,
	onBack,
}: CollectionHeaderProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isModalAddRecipeOpen, setIsModalAddRecipeOpen] =
		useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [collectionTitle, setCollectionTitle] = useState<string>(
		collection.title,
	);

	const { deleteCollectionMutation } = useDeleteCollection();
	const { updateCollectionMutation } = useUpdateCollection();

	useEffect(() => {
		setCollectionTitle(collection.title);
	}, [collection.title]);

	const handleAddRecipeModal = () => setIsModalAddRecipeOpen(true);
	const closeAddRecipeModal = () => setIsModalAddRecipeOpen(false);

	const handleRenameCollectionModal = () => setIsEditing(true);
	const handleSaveNewTitle = (newTitle: string) => {
		updateCollectionMutation.mutate(
			{ id: collection.id, title: newTitle },
			{
				onSuccess: () => {
					setIsEditing(false);
					setCollectionTitle(newTitle);
				},
			},
		);
	};

	const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
	const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
	const handleConfirmDelete = () => {
		deleteCollectionMutation.mutate(collection.id, {
			onSuccess: () => {
				handleCloseDeleteModal();
				onBack();
			},
		});
	};

	return (
		<>
			<div className="w-full flex flex-col items-center min-[550px]:items-start gap-y-4 md:flex-row md:justify-between mb-4 py-2">
				<div className="flex items-start gap-x-2">
					<button onClick={onBack} className="min-w-fit">
						<img
							src={goBackArrowIcon}
							alt="arrow-left"
							className="w-10 p-2 bg-brandOrange rounded-full cursor-pointer transition-transform hover:scale-110"
						/>
					</button>
					<div className="flex flex-col">
						<p className="text-slate-600 text-xs">Back to Collections</p>
						<h3 className="max-w-[380px] lg:max-w-[500px] text-2xl font-semibold break-all">
							{collectionTitle}
						</h3>
					</div>
				</div>
				<div className="flex items-center space-x-4">
					<button
						data-cy="add-recipe-button"
						className="py-2 h-12 flex items-center justify-center rounded-full text-white text-sm bg-brandOrange hover:bg-[#E1B864] sm-custom:px-4 sm-custom:gap-0.5 w-12 sm-custom:w-auto"
						onClick={handleAddRecipeModal}
					>
						<img
							src={addIcon}
							alt="add icon"
							className="w-4 h-4 sm-custom:mb-0.5"
						/>
						<span className="hidden sm-custom:inline">Add Saved Recipe</span>
					</button>
					<CollectionHeaderMenu
						onDelete={handleOpenDeleteModal}
						onRename={handleRenameCollectionModal}
					/>
				</div>
			</div>

			<EditCollectionModal
				isOpen={isEditing}
				onClose={() => setIsEditing(false)}
				onSave={handleSaveNewTitle}
				initialTitle={collectionTitle}
			/>

			<DeleteCollectionConfirmModal
				isOpen={isDeleteModalOpen}
				onClose={handleCloseDeleteModal}
				onConfirm={handleConfirmDelete}
			/>

			{isModalAddRecipeOpen && (
				<SelectRecipeCollectionModal
					open={isModalAddRecipeOpen}
					onClose={closeAddRecipeModal}
					collection={collection}
					onAddRecipe={handleAddRecipeModal}
					onRemoveRecipe={handleAddRecipeModal}
				/>
			)}
		</>
	);
};
