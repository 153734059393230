import { Link } from 'react-router-dom';

export const JoinKitchedLandingPage = () => {
	return (
		<div
			data-cy="join-kitched-landing-page"
			className="min-h-[100vh] max-w-[1536px] mx-auto"
		>
			<div className="flex flex-col justify-center items-center gap-y-8 pt-12">
				<img src="/favicon-192x192.png" alt="Kitched-logo" className="w-20" />
				<p className="max-w-[500px] text-5xl min-[400px]:text-6xl sm:max-w-[600px] sm:text-7xl text-center text-brandRedStrong font-semibold">
					Join our community and events
				</p>
				<Link
					to="/signup"
					className="mt-6 px-10 py-2 text-2xl text-white bg-brandRedStrong rounded-xl shadow-sm"
				>
					Get Kitched
				</Link>
			</div>
			<div className="py-12">
				<img
					src="images/landing-page/kitched-on-mobile.png"
					alt="kitched on mobile"
					className="h-96 mx-auto"
				/>
			</div>
		</div>
	);
};
