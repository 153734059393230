import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

import { subscriptionPlans } from '@/assets/mock/subscription-plans';

import checkedBoxBlackIcon from '@assets/svg/checked-red-icon.svg';

export const PaymentWallLandingPage = () => {
	return (
		<ScrollAnimation
			animateIn={'animate-fadeInUp'}
			animateOnce={true}
			className="max-w-[1536px] min-h-[80vh] mx-auto flex flex-col items-center justify-center gap-y-8"
		>
			<h4 className="text-3xl lg:text-5xl text-center uppercase font-bold text-brandRedStrong">
				Perfect for every home-cook
			</h4>
			<div
				data-cy="payment-wall-landing-page"
				className="w-full flex flex-wrap gap-12 justify-center items-end"
			>
				{subscriptionPlans.map((plan) => (
					<div
						data-cy={`${plan.param}-plan-card`}
						key={plan.name}
						className={`relative flex flex-col w-[300px] rounded-xl p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10 bg-white ${
							plan.param == 'essential' ? 'border border-brandRedStrong' : ''
						}`}
					>
						<div className="flex flex-col gap-x-4 text-brandRedStrong border-b border-x-gray-400 pb-3">
							<h3 className="text-base font-semibold leading-7">{plan.name}</h3>
							<div className="flex items-baseline gap-x-2">
								<span className="text-5xl font-bold tracking-tight">
									{plan.price}
								</span>
							</div>
							{plan.name == 'Basic' ? (
								<p className="text-sm text-[#6a0136]">Free forever</p>
							) : (
								<p className="text-sm text-[#6a0136]">Per month</p>
							)}
						</div>

						<ul role="list" className="mt-4 space-y-3 text-sm leading-6">
							{plan.features.map((feature, index) => (
								<li key={index} className="flex gap-x-3 text-[#6a0136]">
									<img src={checkedBoxBlackIcon} alt="checked-icon" />
									{feature}
								</li>
							))}
						</ul>

						<Link
							data-cy={`${plan.param}-plan-button`}
							className={`block rounded-xl bg-brandRedStrong px-3.5 py-3 mt-8 text-center text-white font-semibold leading-6 shadow-sm border border-white  ${
								plan.name == 'Basic' && 'animate-bounce'
							} ${
								plan.name !== 'Basic' &&
								plan.param !== 'essential' &&
								'pointer-events-none'
							}`}
							to="/signup"
						>
							{plan.name === 'Basic'
								? 'Sign up'
								: plan.param === 'essential'
								? '¡Free trial for 7 days!'
								: 'Coming soon'}
						</Link>
					</div>
				))}
			</div>
		</ScrollAnimation>
	);
};
