import { EventContentArg, EventInput } from '@fullcalendar/core/index.js';

import { CreateMealPlanForm } from './CreateMealPlanForm';
import { DeleteMealPlanForm } from './DeleteMealPlanForm';
import { UpdateMealPlanForm } from './UpdateMealPlanForm';

interface RenderEventContentProps {
	eventContent: EventContentArg;
	events: EventInput[];
	setEvents: React.Dispatch<React.SetStateAction<EventInput[]>>;
	navigate: (path: string) => void;
}

export const RenderEventContent = ({
	eventContent,
	setEvents,
	navigate,
}: RenderEventContentProps) => {
	const { id, title, extendedProps } = eventContent.event;
	const recipe = extendedProps.recipe;

	return (
		<div>
			{title ? (
				<div>
					<div className="relative w-[92%] h-[150px] flex flex-col justify-between p-4 my-2 bg-white rounded-[45px] shadow-lg hover:ring-2 hover:ring-brandRed ">
						<div className="flex justify-between">
							<div className="flex gap-3">
								<span className="font-semibold text-brandRed text-xs pt-2">
									{title}
								</span>
								<div className="flex pt-[7px] gap-1">
									<UpdateMealPlanForm
										selectedEvent={eventContent}
										setEvents={setEvents}
									/>

									<DeleteMealPlanForm
										key={Number(id)}
										id={Number(extendedProps.mealPlanId)}
										selectedEvent={eventContent}
										setEvents={setEvents}
									/>
								</div>
							</div>
							<img
								src={
									recipe.thumbnailUrl ? recipe.thumbnailUrl : recipe.mediaUrl
								}
								alt="recipe image"
								className="h-[50px] w-[50px] rounded-3xl"
							/>
						</div>
						<div className="mb-2">
							<button
								className="text-lg min-[400px]:text-xl sm:text-lg font-semibold text-wrap line-clamp-2 hover:text-brandGreen"
								style={{ maxWidth: '100%' }}
								onClick={() => navigate(`/recipe/detail/${recipe.uuid}`)}
							>
								{recipe.title}
							</button>
							{recipe.servings != null &&
								recipe.servings != 0 &&
								Number.isFinite(recipe.servings) && (
									<div className="text-xs">Serving size {recipe.servings}</div>
								)}
						</div>
					</div>
				</div>
			) : (
				<CreateMealPlanForm
					selectedEvent={eventContent}
					setEvents={setEvents}
				/>
			)}
		</div>
	);
};
