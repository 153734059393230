import { useNavigate } from 'react-router-dom';

import goBackArrowIcon from '@/assets/svg/go-back-arrow.svg';

export const GoBackButtons = () => {
	const navigate = useNavigate();

	return (
		<>
			<div className="hidden sm:flex items-center justify-center gap-x-2 ">
				<button
					onClick={() => navigate(-1)}
					className="min-w-fit"
					data-cy="go-back-big-arrow"
				>
					<img
						src={goBackArrowIcon}
						alt="arrow-left"
						className="w-9 p-2 bg-brandOrange rounded-full cursor-pointer transition-transform hover:scale-110"
					/>
				</button>
			</div>
			<div className="sm:hidden flex items-center justify-start ">
				<button
					onClick={() => navigate(-1)}
					data-cy="go-back-small-arrow"
					className="fc-icon fc-icon-chevron-left"
					role="img"
				></button>
			</div>
		</>
	);
};
