import { Link } from 'react-router-dom';

export const GetKitchedNotificationsLandingPage = () => {
	return (
		<div
			data-cy="get-notifications-landing-page"
			className=" bg-brandRedStrong"
		>
			<div className="min-h-[80vh] xl:max-w-[70vw] mx-auto flex flex-col gap-y-8 lg:flex-row lg:items-center lg:justify-center py-12 ">
				<div className=" text-white lg:basis-1/2">
					<div className="flex flex-col gap-y-4 items-start px-4 sm:px-10">
						<h3 className="text-3xl sm:text-4xl uppercase tracking-wide font-semibold lg:pb-4 lg:text-[2.75rem] lg:leading-normal">
							RECIPE INSPIRATION STRAIGHT TO YOUR INBOX
						</h3>
						<p className="text-xl">
							New recipes, cooking tips, trends, farmer's market deals and
							dinner party ideas
						</p>
						<span className="block px-10 py-2 text-2xl text-brandRedStrong bg-white rounded-xl shadow-sm">
							<Link to="/signup">Get Kitched</Link>
						</span>
					</div>
				</div>
				<div className="lg:basis-1/2">
					<img
						src="/images/landing-page/recipe-10.jpg"
						alt="Founder picture"
						className="w-full px-4 mx-auto sm:w-auto sm:mx-0 lg:h-[600px]"
					/>
				</div>
			</div>
		</div>
	);
};
