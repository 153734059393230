import { useNavigate } from 'react-router-dom';

import { ContentDetailsLandingPage } from './ContentDetailsLandingPage';
import { CreatorsFormLandingPage } from './CreatorsFormLandingPage';
import { GetKitchedNotificationsLandingPage } from './GetKitchedNotificationsLandingPage';
import { JoinKitchedLandingPage } from './JoinKitchedLandingPage';
import { KitchedMissionLandingPage } from './KitchedMissionLandingPage';
import { NavbarLandingPage } from './NavbarLandingPage';
import { PaymentWallLandingPage } from './PaymentWallLandingPage';
import { RecipesSliderLandingPage } from './RecipesSliderLandingPage';
import { StatsSection } from './StatsLandingPage';
import { Button } from './components/Button';

import greenLogo from '@/assets/svg/navbar-logo-green.svg';
import { Footer } from '@/components/footer/Footer';
import { useAuth } from '@/context/useAuth';

export const HomeLandingPage = () => {
	const { user, loading } = useAuth();
	const navigate = useNavigate();

	user && navigate('/home');

	if (loading) {
		return (
			<div className="bg-gradient-to-b from-brandBeigeStrong to-white w-full h-full">
				<div className="flex justify-center items-center w-full h-full pt-32">
					<img
						src={greenLogo}
						alt="Flowbite Logo"
						className="h-10 md:h-14 lg:h-16 animate-pulse"
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<section
				className="flex items-center justify-center bg-gradient-to-b from-brandBeigeStrong to-white p-4 pt-36 pb-20 overflow-hidden"
				id="product"
			>
				<NavbarLandingPage />
				<div className="text-center" data-cy="landing-welcome-section">
					<h1 className="text-brandRedStrong text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-robotoMono animate-rotate-in">
						WHAT WILL YOU
						<br />
						COOK TODAY?
					</h1>
					<div className="font-verdana">
						<p className="text-brandRedStrong text-lg md:text-xl lg:text-2xl mb-8 max-w-2xl mx-auto font-verdana animate-rotate-in animation-delay-300">
							Kitched is a simple tool to help you find, filter and cook recipe
							content from trusted creators
						</p>
						<Button text="Get Kitched" redirectTo="/signup" />
					</div>
				</div>
			</section>

			<RecipesSliderLandingPage />

			<StatsSection />
			<ContentDetailsLandingPage />

			<KitchedMissionLandingPage />

			<div
				id="pricing"
				className="bg-gradient-to-b from-brandBeigeStrong to-[#fef7d3]"
			>
				<PaymentWallLandingPage />
				<CreatorsFormLandingPage />
			</div>

			<JoinKitchedLandingPage />

			<GetKitchedNotificationsLandingPage />

			<Footer isLandingPage={true} />
		</>
	);
};
