export const KitchedMissionLandingPage = () => {
	return (
		<div data-cy="kitched-mission-section" className="bg-brandRedStrong">
			<div className="max-w-[1536px] mx-auto flex flex-col gap-y-8 lg:flex-row lg:items-center lg:justify-center py-12 ">
				<div className="lg:basis-1/2">
					<img
						src="/images/landing-page/lianna-landing-page.jpg"
						alt="Founder picture"
						className="w-full px-4 mx-auto sm:w-auto sm:mx-0 sm:h-[600px] sm:pl-10 lg:mx-auto"
					/>
				</div>
				<div className="text-white lg:basis-1/2">
					<div className="space-y-4 px-4 sm:px-10 lg:w-[600px]">
						<h3 className="text-4xl uppercase font-bold pb-4">
							From the founder
						</h3>
						<p className="text-xl">
							We are all so reliant on social media, but hate the wasted time
							spent scrolling. Kitched is for everyone who loves social media
							recipe content, but finds Instagram and Tiktok impractical,
							saturated and distracting. I want to help people find the recipe
							content they’re looking for, know it works, be inspired and cook
							at home more. We’ve picked the best creators so you get
							trustworthy recipe content with the added practicality of easy
							search, filtering and saving. Most of all, I want everyone to
							experience the proven physcial and mental benefits of home-made.
						</p>
						<span className="block text-2xl">Let's Get Kitched!</span>
						<span className="block text-2xl">Lianna</span>
					</div>
				</div>
			</div>
		</div>
	);
};
