import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import goBackArrowIcon from '@/assets/svg/go-back-arrow.svg';
import { PopUp } from '@/components/common/PopUp';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import { ScrollToTop } from '@/helper/scrollToTop';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import { editRecipeValidationSchema } from '@/helper/validations/recipe.validations';
import { useCreateRecipe } from '@/hooks/recipes/useCreateRecipe';
import { useUpdateRecipe } from '@/hooks/recipes/useUpdateRecipe';
import {
	EditRecipeDto,
	INewRecipeDto,
	IRecipe,
} from '@/interface/recipe.interfaces';
import {
	RecipeDifficulty,
	RecipeServings,
	RecipeTime,
} from '@/pages/chef/chef-profile/enum/recipe.enum';

interface EditRecipe {
	recipesById?: IRecipe;
	chefId?: number;
}

export const RecipeForm = ({ recipesById, chefId }: EditRecipe) => {
	const { updateRecipeMutation } = useUpdateRecipe();
	const { createRecipeMutation } = useCreateRecipe();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);

	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	const EDIT_RECIPE_INITIAL_STATE = {
		title: recipesById?.title || '',
		description: recipesById?.description || '',
		time: recipesById?.time || undefined,
		servings: recipesById?.servings || undefined,
		difficulty: recipesById?.difficulty || undefined,
		tags: recipesById?.tags || [],
		instructions: recipesById?.instructions || [],
		ingredients: recipesById?.ingredients || [],
		nutritionalInformation: recipesById?.nutritionalInformation || undefined,
	};

	const onChangeRecipe = async (values: EditRecipeDto | INewRecipeDto) => {
		if (recipesById) {
			updateRecipeMutation.mutate(
				{
					uuid: recipesById.uuid,
					newRecipe: values,
				},
				{
					onSuccess: () => {
						onCloseModal();
						navigate('/chef/profile');
					},
				},
			);
		} else if (chefId) {
			const newRecipeValues: INewRecipeDto = {
				chef: chefId,
				title: values.title || '',
				ingredients: values.ingredients || [],
				instructions: values.instructions || [],
				description: values.description || '',
				time: values.time || '15',
				difficulty: values.difficulty || 'easy',
				servings: values.servings || '1',
				tags: values.tags || [],
				nutritionalInformation: values.nutritionalInformation || undefined,
			};
			createRecipeMutation.mutate(newRecipeValues, {
				onSuccess: () => {
					onCloseModal();
					navigate('/chef/profile');
				},
			});
		}
	};

	return (
		<div className="xl:w-[80vw] xl:mx-auto">
			<ScrollToTop />
			<div className="flex justify-between items-start sm:items-center">
				<div className="flex flex-col items-start gap-2 text-xl min-[400px]:text-2xl md:flex-row sm:items-center sm:text-3xl">
					{recipesById ? (
						<span>Set new values to</span>
					) : (
						<span>Create your new recipe</span>
					)}

					{recipesById && (
						<span className="text-white font-medium bg-brandGreen py-1 px-3 rounded-xl tracking-wide">
							{upperCaseFirstLetter(recipesById?.title as string)}
						</span>
					)}
				</div>
				<img
					src={goBackArrowIcon}
					alt="arrow-left"
					className="p-2 bg-brandOrange rounded-full cursor-pointer transition-transform hover:scale-110"
					onClick={() => navigate(-1)}
				/>
			</div>

			<Formik
				initialValues={EDIT_RECIPE_INITIAL_STATE}
				validationSchema={editRecipeValidationSchema}
				onSubmit={() => onOpenModal()}
			>
				{({ values, resetForm, isValid, isSubmitting }) => (
					<Form
						data-cy="edit-recipe-form"
						className="my-10 bg-brandOrange p-10 rounded-3xl"
					>
						<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
							<div className="sm:col-span-3">
								<label htmlFor="title" className="text-xl tracking-wide">
									Title
								</label>
								<Field
									data-cy="edit-recipe-title-input"
									name="title"
									type="text"
									className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
								/>
								<ErrorMessage
									name="title"
									component="p"
									className="text-red-500 text-sm"
								/>
							</div>

							<div className="sm:col-span-3">
								<label htmlFor="description" className="text-xl tracking-wide">
									Description
								</label>
								<Field
									data-cy="edit-recipe-description-input"
									name="description"
									type="text"
									className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
								/>
								<ErrorMessage
									name="description"
									component="p"
									className="text-red-500 text-sm"
								/>
							</div>

							<div className="sm:col-span-2">
								<label htmlFor="time" className="text-lg tracking-wide">
									Time
								</label>
								<Field
									name="time"
									as="select"
									className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
								>
									<option value={RecipeTime.FIFTY_MIN}>15 min</option>
									<option value={RecipeTime.THIRTY_MIN}>30 min</option>
									<option value={RecipeTime.FORTYFIVE_MIN}>45 min</option>
									<option value={RecipeTime.ONE_HOUR}>60 min</option>
									<option value={RecipeTime.ONE_HALF_HOUR}>90 min</option>
									<option value={RecipeTime.TWO_HOUR}>120 min</option>
									<option value={RecipeTime.MORE_THAN_TWO_HOUR}>
										More than two hours
									</option>
								</Field>
								<ErrorMessage
									name="time"
									component="p"
									className="text-red-500 text-sm"
								/>
							</div>

							<div className="sm:col-span-2">
								<label htmlFor="servings" className="text-lg tracking-wide">
									Servings
								</label>
								<Field
									name="servings"
									as="select"
									className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
								>
									<option value={RecipeServings.ONE}>1</option>
									<option value={RecipeServings.TWO}>2</option>
									<option value={RecipeServings.THREE}>3</option>
									<option value={RecipeServings.FOUR}>4</option>
									<option value={RecipeServings.FIVE}>5</option>
									<option value={RecipeServings.SIX}>6</option>
									<option value={RecipeServings.SEVEN}>7</option>
									<option value={RecipeServings.MORE_THAN_SEVEN}>
										More than 7
									</option>
								</Field>
								<ErrorMessage
									name="servings"
									component="p"
									className="text-red-500 text-sm"
								/>
							</div>

							<div className="sm:col-span-2">
								<label htmlFor="difficulty" className="text-lg tracking-wide">
									Difficulty
								</label>
								<Field
									data-cy="edit-recipe-difficulty-input"
									name="difficulty"
									as="select"
									className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
								>
									<option value={RecipeDifficulty.EASY}>Easy</option>
									<option value={RecipeDifficulty.MEDIUM}>Medium</option>
									<option value={RecipeDifficulty.HARD}>Hard</option>
								</Field>
								<ErrorMessage
									name="difficulty"
									component="p"
									className="text-red-500 text-sm"
								/>
							</div>

							<div className="sm:col-span-full sm:mt-8">
								<label htmlFor="tags" className="text-xl tracking-wide">
									Tags
								</label>
								<FieldArray
									name="tags"
									render={(arrayHelpers) => (
										<div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
											{values?.tags?.map((tag, index) => (
												<div
													key={tag.id}
													className="sm:col-span-2 md:col-span-1"
												>
													<Field
														data-cy="edit-recipe-tags-name-input"
														name={`tags[${index}].name`}
														type="text"
														className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
													/>
													<button
														type="button"
														onClick={() => arrayHelpers.remove(index)}
														className="text-brandGreen text-xs rounded-sm bg-slate-100 px-3 mt-1 transition-colors hover:bg-brandGreen hover:text-white"
													>
														Remove
													</button>
													<ErrorMessage
														name={`tags[${index}].name`}
														component="p"
														className="text-red-500 text-xs text-center"
													/>
												</div>
											))}
											<button
												data-cy="edit-recipe-tags-add-button"
												type="button"
												onClick={() =>
													arrayHelpers.push({
														name: '',
													})
												}
												className={`place-self-start mt-2 px-4 py-2 sm:col-span-2 md:col-span-1 text-brandGreen text-sm rounded-md bg-slate-100 transition-colors ${
													values.tags.length > 0 &&
													values.tags[values.tags.length - 1].name.trim() === ''
														? ' cursor-not-allowed opacity-50'
														: 'hover:bg-brandGreen hover:text-white'
												}`}
												disabled={
													values.tags.length > 0 &&
													values.tags[values.tags.length - 1].name.trim() === ''
												}
											>
												New Tag
											</button>
										</div>
									)}
								/>
							</div>

							<div className="sm:col-span-full sm:mt-8 grid ">
								<label
									htmlFor="ingredients"
									className="text-xl tracking-wide mb-4"
								>
									Ingredients
								</label>
								<FieldArray
									name="ingredients"
									render={(arrayHelpers) => (
										<div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
											{values?.ingredients?.map((ingredients, index) => (
												<div
													key={ingredients.id}
													className="sm:col-span-2 md:col-span-1 space-y-2"
												>
													<div className="flex flex-col">
														<label
															htmlFor={`ingredients[${index}].name`}
															className="text-xs mb-[-7px] ml-1"
														>
															Name
														</label>
														<Field
															data-cy="edit-recipe-ingredient-name-input"
															name={`ingredients[${index}].name`}
															type="text"
															className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
														/>
													</div>
													<div className="flex flex-col">
														<label
															htmlFor={`ingredients[${index}].unit`}
															className="text-xs mb-[-7px] ml-1"
														>
															Unit
														</label>
														<Field
															name={`ingredients[${index}].unit` || ''}
															type="text"
															className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
														/>
													</div>
													<div className="flex flex-col">
														<label
															htmlFor={`ingredients[${index}].quantity`}
															className="text-xs mb-[-7px] ml-1"
														>
															Quantity
														</label>
														<Field
															name={`ingredients[${index}].quantity` || ''}
															type="number"
															className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
														/>
													</div>
													<ErrorMessage
														name={`ingredients[${index}].name`}
														component="p"
														className="text-red-500 text-xs"
													/>
													<ErrorMessage
														name={`ingredients[${index}].unit`}
														component="p"
														className="text-red-500 text-xs"
													/>
													<ErrorMessage
														name={`ingredients[${index}].quantity`}
														component="p"
														className="text-red-500 text-xs"
													/>
													<button
														type="button"
														onClick={() => arrayHelpers.remove(index)}
														className="text-brandGreen text-xs rounded-sm bg-slate-100 px-3 mt-1 transition-colors hover:bg-brandGreen hover:text-white"
													>
														Remove
													</button>
												</div>
											))}
											<button
												data-cy="edit-recipe-ingredient-add-button"
												type="button"
												onClick={() =>
													arrayHelpers.push({
														name: '',
														unit: '',
														quantity: '',
													})
												}
												className={`place-self-start mt-[17px] px-4 py-2 sm:col-span-2 md:col-span-1 text-brandGreen text-sm rounded-md bg-slate-100 transition-colors ${
													values.ingredients.length > 0 &&
													values.ingredients[
														values.ingredients.length - 1
													].name.trim() === '' &&
													values.ingredients[
														values.ingredients.length - 1
													].unit.trim() === '' &&
													values.ingredients[
														values.ingredients.length - 1
													].quantity.trim() === ''
														? 'cursor-not-allowed opacity-50'
														: 'hover:bg-brandGreen hover:text-white'
												}`}
												disabled={
													values.ingredients.length > 0 &&
													values.ingredients[
														values.ingredients.length - 1
													].name.trim() === '' &&
													values.ingredients[
														values.ingredients.length - 1
													].unit.trim() === '' &&
													values.ingredients[
														values.ingredients.length - 1
													].quantity.trim() === ''
												}
											>
												New Ingredient
											</button>
										</div>
									)}
								/>
							</div>

							<div className="sm:col-span-full sm:mt-6 grid ">
								<label
									htmlFor="instructions"
									className="text-xl tracking-wide mb-4"
								>
									Method
								</label>
								<FieldArray
									name="instructions"
									render={(arrayHelpers) => (
										<div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
											{values?.instructions?.map((instruction, index) => (
												<div
													key={instruction.id}
													className="sm:col-span-3 md:col-span-2"
												>
													<div className="flex items-center gap-x-2">
														<label htmlFor={`instructions[${index}].number`}>
															Step:
														</label>
														<Field
															data-cy="edit-recipe-instructions-number-input"
															name={`instructions[${index}].number`}
															type="number"
															className="w-12 pl-2 text-slate-700 rounded-md border-none outline-none shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
															value={
																values.instructions[index]?.number || index + 1
															}
														/>
													</div>
													<Field
														data-cy="edit-recipe-instructions-description-input"
														name={`instructions[${index}].description`}
														as="textarea"
														rows={6}
														className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
													/>
													<ErrorMessage
														name={`instructions[${index}].description`}
														component="p"
														className="text-red-500 text-xs"
													/>
													<ErrorMessage
														name={`instructions[${index}].number`}
														component="p"
														className="text-red-500 text-xs"
													/>
													<button
														type="button"
														onClick={() => arrayHelpers.remove(index)}
														className="text-brandGreen text-xs rounded-sm bg-slate-100 px-3 mt-1 transition-colors hover:bg-brandGreen hover:text-white"
													>
														Remove
													</button>
												</div>
											))}
											<button
												data-cy="edit-recipe-instructions-add-button"
												type="button"
												onClick={() => {
													const lastIndex = values.instructions.length - 1;
													const lastInstruction =
														values.instructions[lastIndex];
													const nextNumber = lastInstruction
														? lastInstruction.number + 1
														: values.instructions.length + 1;
													arrayHelpers.push({
														number: nextNumber,
														description: '',
													});
												}}
												className={`place-self-start mt-2 px-4 py-2 sm:col-span-2 md:col-span-1 text-brandGreen text-sm rounded-md bg-slate-100 transition-colors ${
													values.instructions.length > 0 &&
													!values.instructions[values.instructions.length - 1]
														?.description
														? 'cursor-not-allowed opacity-50'
														: 'hover:bg-brandGreen hover:text-white'
												}`}
												disabled={
													values.instructions.length > 0 &&
													!values.instructions[values.instructions.length - 1]
														?.description
												}
											>
												New Step
											</button>
										</div>
									)}
								/>
							</div>
							<div className="sm:col-span-full sm:mt-8 space-y-2">
								<label
									htmlFor="nutritionalInformation"
									className="text-xl tracking-wide"
								>
									Nutritional Information
								</label>
								<div className="grid grid-cols-1 sm:grid-cols-8 gap-6">
									<div className="sm:col-span-2">
										<label htmlFor={`nutritionalInformation.calories`}>
											Calories
										</label>
										<Field
											data-cy="edit-recipe-calories-input"
											name={`nutritionalInformation.calories`}
											type="number"
											className="w-full pl-2 text-slate-700 rounded-md border-none outline-none shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
										/>
										<ErrorMessage
											name={`nutritionalInformation.calories`}
											component="p"
											className="text-red-500 text-xs"
										/>
									</div>
									<div className="sm:col-span-2">
										<label htmlFor={`nutritionalInformation.fat`}>Fat</label>
										<Field
											name={`nutritionalInformation.fat`}
											type="number"
											className="w-full pl-2 text-slate-700 rounded-md border-none outline-none shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
										/>
										<ErrorMessage
											name={`nutritionalInformation.fat`}
											component="p"
											className="text-red-500 text-xs"
										/>
									</div>
									<div className="sm:col-span-2">
										<label htmlFor={`nutritionalInformation.protein`}>
											Protein
										</label>
										<Field
											name={`nutritionalInformation.protein`}
											type="number"
											className="w-full pl-2 text-slate-700 rounded-md border-none outline-none shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
										/>
										<ErrorMessage
											name={`nutritionalInformation.protein`}
											component="p"
											className="text-red-500 text-xs"
										/>
									</div>
									<div className="sm:col-span-2">
										<label htmlFor={`nutritionalInformation.carbohydrates`}>
											Carbohydrates
										</label>
										<Field
											name={`nutritionalInformation.carbohydrates`}
											type="number"
											className="w-full pl-2 text-slate-700 rounded-md border-none outline-none shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
										/>
										<ErrorMessage
											name={`nutritionalInformation.carbohydrates`}
											component="p"
											className="text-red-500 text-xs"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex gap-x-4 mt-16">
							<button
								disabled={
									values === EDIT_RECIPE_INITIAL_STATE ||
									isSubmitting ||
									!isValid
								}
								type="submit"
								className="w-24 text-brandGreen rounded-md bg-slate-100 py-2 font-semibold shadow-sm transition-colors hover:bg-brandGreen hover:text-white disabled:cursor-not-allowed"
								data-cy="confirm-edit-recipe-button"
							>
								Confirm
							</button>
							<button
								disabled={values === EDIT_RECIPE_INITIAL_STATE}
								onClick={() => resetForm()}
								className="w-24 text-brandGreen rounded-md bg-slate-100 py-2 font-semibold shadow-sm transition-colors hover:bg-brandRed/70 hover:text-white disabled:cursor-not-allowed"
								data-cy="reset-edit-recipe-button"
							>
								Reset
							</button>
						</div>
						<PopUp open={open} onClose={onCloseModal} topCss={'15vh'}>
							<div className="flex flex-col gap-y-2 p-6 ">
								<p className="text-xl text-center text-slate-600 font-semibold my-4">
									{recipesById
										? 'Your recipe will be updated'
										: 'Your recipe will be created'}
								</p>
								<ActionButtons
									buttonOneText="Yes"
									buttonTwoText="No"
									onButtonClickOne={() => onChangeRecipe(values)}
									onButtonClickTwo={onCloseModal}
								/>
							</div>
						</PopUp>
					</Form>
				)}
			</Formik>
		</div>
	);
};
