interface IconProps {
	scrolled: boolean;
	path: string;
}

export function RecipeIcon({ scrolled, path }: IconProps) {
	const iconWhite = !scrolled && path === '/home';

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.001 5H20.001"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.001 8H17.001"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.001 11.5C21.001 16.75 16.751 21 11.501 21C6.25098 21 2.00098 16.75 2.00098 11.5C2.00098 6.25 6.25098 2 11.501 2"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.001 22L20.001 20"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function CreatorsIcon({ scrolled, path }: IconProps) {
	const iconWhite = !scrolled && path === '/home';
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.15298 11.7105L12.9666 11.7039M8.49988 1.3335C9.92776 1.3335 11.1204 2.3335 11.4107 3.66757C12.1732 3.46227 12.9863 3.56674 13.6713 3.958C14.3563 4.34926 14.8571 4.99525 15.0634 5.75387C15.2697 6.51248 15.1648 7.32158 14.7715 8.00318C14.3783 8.68477 13.7291 9.18301 12.9666 9.38831V14.6668H4.03312V9.38831C3.65561 9.28656 3.30193 9.11182 2.99229 8.87407C2.68264 8.63632 2.42309 8.34021 2.22846 8.00265C2.03382 7.66509 1.90792 7.2927 1.85793 6.90672C1.80793 6.52075 1.83484 6.12876 1.9371 5.75313C2.03936 5.3775 2.21497 5.02559 2.45392 4.71749C2.69287 4.40939 2.99046 4.15114 3.32971 3.95748C3.66897 3.76381 4.04323 3.63854 4.43115 3.58879C4.81906 3.53905 5.21302 3.56582 5.59053 3.66757C5.73484 3.00651 6.10214 2.41448 6.6314 1.98987C7.16066 1.56526 7.82001 1.33364 8.49988 1.3335Z"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function SavedRecipeIcon({ scrolled, path }: IconProps) {
	const iconWhite = !scrolled && path === '/home';
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.499 10.6484H9.49902"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 8.21094V13.2109"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.8213 2H7.18129C5.05129 2 3.32129 3.74 3.32129 5.86V19.95C3.32129 21.75 4.61129 22.51 6.19129 21.64L11.0713 18.93C11.5913 18.64 12.4313 18.64 12.9413 18.93L17.8213 21.64C19.4013 22.52 20.6913 21.76 20.6913 19.95V5.86C20.6813 3.74 18.9513 2 16.8213 2Z"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function PlannerIcon({ scrolled, path }: IconProps) {
	const iconWhite = !scrolled && path === '/home';

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 2V5"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 2V5"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.5 9.09375H20.5"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22 19C22 19.75 21.79 20.46 21.42 21.06C20.73 22.22 19.46 23 18 23C16.99 23 16.07 22.63 15.37 22C15.06 21.74 14.79 21.42 14.58 21.06C14.21 20.46 14 19.75 14 19C14 16.79 15.79 15 18 15C19.2 15 20.27 15.53 21 16.36C21.62 17.07 22 17.99 22 19Z"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.4404 19.0034L17.4304 19.9934L19.5604 18.0234"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9955 13.7031H12.0045"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.29431 13.7031H8.30329"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.29431 16.7031H8.30329"
				stroke={iconWhite ? 'white' : '#000000'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
